export const materialesDump = [
    {
        "label": "Seminarios",
        "value": "seminarios"
    },
    {
        "label": "CD",
        "value": "cd"
    },
    {
        "label": "Folletos",
        "value": "folletos"
    },
    {
        "label": "Libros",
        "value": "libros"
    },
    {
        "label": "Ebook",
        "value": "ebook"
    },
    {
        "label": "Revistas",
        "value": "revistas"
    },
    {
        "label": "Tesis",
        "value": "tesis"
    },
    {
        "label": "Tesis digital",
        "value": "tesis digital"
    },
    {
        "label": "EPS",
        "value": "eps"
    },
    {
        "label": "EPS digital",
        "value": "eps digital"
    },
    {
        "label": "Mapas",
        "value": "mapas"
    },
    {
        "label": "Monografías",
        "value": "monografias"
    },
    {
        "label": "Braile",
        "value": "braile"
    },
    {
        "label": "DVD",
        "value": "dvd"
    }
]
