import React from "react";
import dateFormat from "dateformat";

import app_params from "../../config/params";
import styles from "./Footer.module.scss";

const Footer = (props) => {
  var now = new Date();
  return (
    <footer className={`text-center text-lg-start theme-footer`}>
      <section className={`border-bottom ${styles.fist_section}`}>
        <div className="container text-center text-md-start mt-5">
          <div className="row">
            <div className={`col-12 col-lg-6 mx-auto mb-md-0 mb-1 ${styles.contact}`}>
              <h6 className="text-uppercase fw-bold mb-4">Contacto</h6>
              <p>
                <i className="fas fa-home me-3"></i> Ciudad Universitaria Zona
                12, Edificio de Recursos Educativos, Ciudad de Guatemala
              </p>
              <p>
                <a href="mailto:bibliotecacentral@usac.edu.gt">
                  <i className="fas fa-envelope me-3"></i>
                  bibliotecacentral@usac.edu.gt
                </a>
              </p>
              <p>
                <a href="tel:51546475">
                  <i className="fas fa-phone-alt me-3"></i>
                  (+502) 5154 6475
                </a>
              </p>
              <div className="mb-1">
                <a
                  href="https://www.facebook.com/BiblioUSACentral"
                  className="me-4 text-reset"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a
                  href="https://www.instagram.com/bibliocentralusac"
                  className="me-4 text-reset"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-instagram"></i>
                </a>
                <a
                  href="https://twitter.com/bibliocentralu1"
                  className="me-4 text-reset"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-twitter"></i>
                </a>
                <a
                  href="https://www.youtube.com/@bibliousac"
                  className="me-4 text-reset"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-youtube"></i>
                </a>
              </div>
            </div>
            <div className="col-12 col-lg-6 mx-auto mb-4 text-center">
              {/* <h6 className="text-uppercase fw-bold mb-4">
                Biblioteca Central
              </h6> */}
              <img
                src={`${app_params.basepath}/assets/logos/JUNTOSHACEMOSUNIVERSIDAD_LOGO.png`}
                alt="slogan"
                className="d-inline-block align-middle eslogan"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="d-lg-flex flex-row justify-content-center p-3">
        <div className="d-flex flex-row justify-content-center mx-lg-5">
          <span className="glifos-font">GLIFOS - library</span>
          <span className="ms-1">ver. {app_params.version}</span>
        </div>
        <div className="d-flex flex-row justify-content-center mt-3 mt-lg-0 mx-lg-5">
          Copyright &copy; 2000 - {dateFormat(now, "yyyy")} Infolib, S.A.
        </div>
        <div className="d-flex flex-column justify-content-center mt-3 mt-lg-0 mx-lg-5">
          <div className="d-flex flex-row justify-content-center">
            <a href="https://glifos.com" target="_blank" rel="noreferrer">
              <img
                src={`${app_params.basepath}/assets/logos/poweredByGLIFOS.png`}
                alt="poweredByGLIFOS"
                width="63"
                height="30"
                className="rounded"
              />
            </a>
          </div>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
